$color__main: var(--color__brand);
$color__error: #b92200;
$color__info: #b92200;
$color__success: #2f9f09;

.toastify {
  padding: 12px 20px;
  color: #ffffff;
  font-weight: bold;
  display: inline-block;
  box-shadow: 0 3px 6px -1px rgba(0, 0, 0, 0.12), 0 10px 36px -4px rgba(77, 96, 232, 0.3);
  background: $color__main;
  position: fixed;
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  max-width: calc(50% - 20px);
  z-index: 2147483647;
  font-size: 14px;

  &.info {
    background: $color__info;
    box-shadow: 0 3px 6px -1px rgba($color__info, 0.12), 0 10px 36px -4px rgba($color__info, 0.3);
  }

  &.error {
    background: $color__error;
    box-shadow: 0 3px 6px -1px rgba($color__error, 0.12), 0 10px 36px -4px rgba($color__error, 0.3);
  }

  &.success {
    background: $color__success;
    box-shadow: 0 3px 6px -1px rgba($color__success, 0.12), 0 10px 36px -4px rgba($color__success, 0.3);
  }
}

.toastify.on {
  opacity: 1;
}

.toast-close {
  background: transparent;
  border: 0;
  color: white;
  cursor: pointer;
  font-family: inherit;
  font-size: 1em;
  opacity: 0.4;
  padding: 0 5px;
}

.toastify-right {
  right: 15px;
}

.toastify-left {
  left: 15px;
}

.toastify-top {
  top: -150px;
}

.toastify-bottom {
  bottom: -150px;
}

.toastify-rounded {
  border-radius: 25px;
}

.toastify-avatar {
  width: 1.5em;
  height: 1.5em;
  margin: -7px 5px;
  border-radius: 2px;
}

.toastify-center {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  max-width: fit-content;
  max-width: -moz-fit-content;
}

@media only screen and (max-width: 360px) {
  .toastify-right, .toastify-left {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    max-width: fit-content;
  }
}