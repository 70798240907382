.container-privacy {
    h2 {
        font-size: 1.2em;
        margin: 20px 0 15px 0;
    }
    h3 {
        font-size: 1em;
        margin: 15px 0 10px 0;
    }
    li { margin-left: 20px; }
    p { margin: 10px 0; }
}