@import "generic/normalize";
@import "generic/reset";

@import "typography/roboto.css";
@import "typography/mulish.css";
@import "typography/granville.css";

@import "toastify";

body {
	font-size: 16px ! important;
	font-family: "Mulish", "Roboto", sans-serif;
	background-color: var(--color__background);
	padding: 30px;
}
