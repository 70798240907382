.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: var(--color__brand);
  border-top: 1px solid black;
  color: white;

  table {
    border-spacing: 0;
    border-collapse: collapse;
    min-width: 100%;
    table-layout: fixed;
    padding-bottom: 0px;
  }

  tr,
  td {
    padding: 0px 4px;
  }

  .footer-container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-items: center;
    padding: 10px;
  }

  &__price {
    text-align: end;

    &__discount {
      font-size: 16px;
      td {
        padding-top: 0.4em;
      }
    }

    &__total {
      font-size: 20px;
    }
  }
  &__left {
    text-align: left;
    font-size: 11px;
    flex-grow: 1;
    padding: 4px;
  }

  &__notes {
    padding: 3px;
  }
  &__links {
    padding: 3px;
  }

  a {
    text-decoration: none;
    color: #fff;
  }

  &__total_amt {
    font-weight: bold;
    text-align: right;
  }
  &__total_currency {
    font-weight: bold;
    text-align: left;
  }

  &__discount_amt {
    text-align: right;
  }
  &__discount_currency {
    text-align: left;
  }
}
