.submitOrder {
  &__title {
    font-family: inherit !important;
    font-weight: bold;
    text-transform: uppercase;
    color: var(--color__brand);
  }
  &__wrapper {
    padding: 2rem 2rem 3rem !important;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  &__text {
    display: block;
    margin-bottom: .5rem;
    font-family: inherit !important;
  }
  &__buttons {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
    @media (min-width: 768px) {
      justify-content: start;
    }
    &__btn {
      transition: transform 0.1s ease !important;
      box-shadow: 0 2px 4px rgba(black, 0.2);
      min-width: 100% !important;
      @media (min-width: 768px) {
        min-width: 10rem !important;
      }
      &:hover {
        transform: scale(1.08);
      }
      &:active {
        transform: scale(0.92);
      }
      
    } 
  }
  &__submit{
    background-color: var(--color__brand) !important;
    &:disabled {
      color: var(--color__background) !important;
    }
  }
  &__cancel{
    background-color: var(--color__back) !important;
  }
  &__input {
    input, textarea {
      text-align: left !important;
    }
    > div {
      border-radius: 8px;
      &.Mui-focused {
        border-color: var(--color__brand) !important;
        box-shadow: none !important;
        outline: none !important;
        fieldset {
          border-color: var(--color__brand) !important;
        }
      }
    }
    label {
      &.Mui-focused {
        color: var(--color__brand) !important;
      }
    }
  }
}