/* mulish-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 300;
  src: url('../../assets/fonts/mulish/mulish-v12-latin-300.eot'); /* IE9 Compat Modes */
  src: url('../../assets/fonts/mulish/mulish-v12-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/mulish/mulish-v12-latin-300.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/mulish/mulish-v12-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/mulish/mulish-v12-latin-300.svg#Mulish') format('svg'); /* Legacy iOS */
}
/* mulish-300italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Mulish';
  font-style: italic;
  font-weight: 300;
  src: url('../../assets/fonts/mulish/mulish-v12-latin-300italic.eot'); /* IE9 Compat Modes */
  src: url('../../assets/fonts/mulish/mulish-v12-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/mulish/mulish-v12-latin-300italic.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/mulish/mulish-v12-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/mulish/mulish-v12-latin-300italic.svg#Mulish') format('svg'); /* Legacy iOS */
}
/* mulish-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/mulish/mulish-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('../../assets/fonts/mulish/mulish-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/mulish/mulish-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/mulish/mulish-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/mulish/mulish-v12-latin-regular.svg#Mulish') format('svg'); /* Legacy iOS */
}
/* mulish-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Mulish';
  font-style: italic;
  font-weight: 400;
  src: url('../../assets/fonts/mulish/mulish-v12-latin-italic.eot'); /* IE9 Compat Modes */
  src: url('../../assets/fonts/mulish/mulish-v12-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/mulish/mulish-v12-latin-italic.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/mulish/mulish-v12-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/mulish/mulish-v12-latin-italic.svg#Mulish') format('svg'); /* Legacy iOS */
}
/* mulish-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 500;
  src: url('../../assets/fonts/mulish/mulish-v12-latin-500.eot'); /* IE9 Compat Modes */
  src: url('../../assets/fonts/mulish/mulish-v12-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/mulish/mulish-v12-latin-500.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/mulish/mulish-v12-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/mulish/mulish-v12-latin-500.svg#Mulish') format('svg'); /* Legacy iOS */
}
/* mulish-500italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Mulish';
  font-style: italic;
  font-weight: 500;
  src: url('../../assets/fonts/mulish/mulish-v12-latin-500italic.eot'); /* IE9 Compat Modes */
  src: url('../../assets/fonts/mulish/mulish-v12-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/mulish/mulish-v12-latin-500italic.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/mulish/mulish-v12-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/mulish/mulish-v12-latin-500italic.svg#Mulish') format('svg'); /* Legacy iOS */
}
/* mulish-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  src: url('../../assets/fonts/mulish/mulish-v12-latin-600.eot'); /* IE9 Compat Modes */
  src: url('../../assets/fonts/mulish/mulish-v12-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/mulish/mulish-v12-latin-600.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/mulish/mulish-v12-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/mulish/mulish-v12-latin-600.svg#Mulish') format('svg'); /* Legacy iOS */
}
/* mulish-600italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Mulish';
  font-style: italic;
  font-weight: 600;
  src: url('../../assets/fonts/mulish/mulish-v12-latin-600italic.eot'); /* IE9 Compat Modes */
  src: url('../../assets/fonts/mulish/mulish-v12-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/mulish/mulish-v12-latin-600italic.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/mulish/mulish-v12-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/mulish/mulish-v12-latin-600italic.svg#Mulish') format('svg'); /* Legacy iOS */
}
/* mulish-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/fonts/mulish/mulish-v12-latin-700.eot'); /* IE9 Compat Modes */
  src: url('../../assets/fonts/mulish/mulish-v12-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/mulish/mulish-v12-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/mulish/mulish-v12-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/mulish/mulish-v12-latin-700.svg#Mulish') format('svg'); /* Legacy iOS */
}
/* mulish-700italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Mulish';
  font-style: italic;
  font-weight: 700;
  src: url('../../assets/fonts/mulish/mulish-v12-latin-700italic.eot'); /* IE9 Compat Modes */
  src: url('../../assets/fonts/mulish/mulish-v12-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/mulish/mulish-v12-latin-700italic.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/mulish/mulish-v12-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/mulish/mulish-v12-latin-700italic.svg#Mulish') format('svg'); /* Legacy iOS */
}
/* mulish-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 800;
  src: url('../../assets/fonts/mulish/mulish-v12-latin-800.eot'); /* IE9 Compat Modes */
  src: url('../../assets/fonts/mulish/mulish-v12-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/mulish/mulish-v12-latin-800.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/mulish/mulish-v12-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/mulish/mulish-v12-latin-800.svg#Mulish') format('svg'); /* Legacy iOS */
}
/* mulish-800italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Mulish';
  font-style: italic;
  font-weight: 800;
  src: url('../../assets/fonts/mulish/mulish-v12-latin-800italic.eot'); /* IE9 Compat Modes */
  src: url('../../assets/fonts/mulish/mulish-v12-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/mulish/mulish-v12-latin-800italic.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/mulish/mulish-v12-latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/mulish/mulish-v12-latin-800italic.svg#Mulish') format('svg'); /* Legacy iOS */
}
/* mulish-900 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 900;
  src: url('../../assets/fonts/mulish/mulish-v12-latin-900.eot'); /* IE9 Compat Modes */
  src: url('../../assets/fonts/mulish/mulish-v12-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/mulish/mulish-v12-latin-900.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/mulish/mulish-v12-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/mulish/mulish-v12-latin-900.svg#Mulish') format('svg'); /* Legacy iOS */
}
/* mulish-900italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Mulish';
  font-style: italic;
  font-weight: 900;
  src: url('../../assets/fonts/mulish/mulish-v12-latin-900italic.eot'); /* IE9 Compat Modes */
  src: url('../../assets/fonts/mulish/mulish-v12-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/mulish/mulish-v12-latin-900italic.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/mulish/mulish-v12-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/mulish/mulish-v12-latin-900italic.svg#Mulish') format('svg'); /* Legacy iOS */
}