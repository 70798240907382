.product-table {
  &__table-wrap {
    overflow-x: auto;
    margin-bottom: 70px;
    table {
      border-spacing: 0;
      border-collapse: collapse;
      min-width: 100%;
      table-layout: fixed;
      font-size: 15px;

      .select_th {
        .select_filter_header_div {
          margin-top: 5px;
        }
        .select_filter_div {
          padding: 10px 5px 5px 5px;
          select {
            width: 100%;
            border: none;
          }
        }
      }

      th,
      td {
        text-align: center;
        border: 1px solid var(--color__text);
        padding: 10px;
        position: relative;
        text-overflow: ellipsis;
        &:has(.product-table__badge) {
          padding: 28px 10px 18px 10px;
        }
      }

      td.variant_color {
        width: 10px;
      }
    }
    th {
      //height: 5rem;
      color: #ffffff;
      text-align: left;
      background-color: var(--color__brand);
      font-weight: bold;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: center;

      &.thumbnail {
        width: 100px;
      }

      &.name {
        width: 300px;
      }

      &.category {
        width: 180px;
      }

      &.color {
        width: 130px;
      }

      &.sampleQty {
        div {
          span {
            position: relative;
            padding-right: 0.8rem;
          }
        }
      }

      &.sampleQty, &.testerQty{
        div {
          display: flex;
          gap: 2px;
        }
        .subHeader {
          display: block;
          font-size: 0.7em;
          padding: 3px;
        }
      }
    }

    tr {
      &:nth-child(odd) {
        background: var(--color__brand-light);
        &:hover {
          background: rgba(white, 0.8);
        }
      }
      &:nth-child(even) {
        background: var(--color__brand-lighter);
        &:hover {
          background: rgba(white, 0.9);
        }
      }
    }
  }

  &__badge {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 10px;
    padding: 4px 6px 5px 7px;
    border-bottom-left-radius: 4px;
    background: var(--color__brand);
    color: #e7d6d9;
    max-width: 157px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.isSample_popover {
  margin: 1rem;
  white-space: pre-line;
  text-align: center;
  font-size: 15px;
  @media (max-width: 767px) {
    font-size: 14px;
  }
  &_paper {
    overflow: visible !important;
    min-height: 2rem !important;
    margin: 2px;
    position: relative;
    &::after {
      content: '';
      width: 0; 
      height: 0; 
      border-top: 10px solid white;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent; 
      position: absolute;
      top: 100%;
      left: calc(50% - 10px);
    }
  }

}

.actions {
  margin-bottom: 20px;
  margin-top: 15px;
  position: sticky;
  display: flex;
  top: 20px;
  z-index: 20;
  justify-content: space-between;
  align-items: center;
  margin-left: -4px;
  margin-right: -4px;

  &__left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__input_field {
    padding: 10px 15px;
    margin: 5px;
    width: 220px;
    border-radius: 5px;
    border: 2px solid white;
    text-align: left;
    -webkit-appearance: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  &__action {
    padding: 10px 15px;
    background: #fff;
    border-radius: 5px;
    transition: transform 0.1s ease;
    font-size: 14px;
    font-weight: bold;
    border: 2px solid white;
    box-shadow: 0 2px 4px rgba(black, 0.2);
    margin: 5px;
    &.-loading {
      cursor: progress;
      transform: scale(1.1) !important;
      opacity: 0.5;
    }

    &:focus {
      border: 2px solid var(--color__brand-light);
    }

    &:hover {
      transform: scale(1.08);
    }

    &:active {
      transform: scale(0.92);
    }
  }
}

@media (max-width: 767px) {
  .actions {
    flex-direction: column;
  }
}

.color_span {
  display: flex;
  max-width: 105px;
  align-items: center;
  justify-content: center;
  padding-left: 22px;

  &__preview {
    position: absolute;
    width: 22px;
    height: 100%;
    top: 0;
    left: 0;
    border-right: 1px solid var(--color__text);
  }
}

td .thumbnail:hover {
  transform: scale(1.5);
}

.thumbnail {
  width: 75px;
  max-width: 75px !important;
}

.price {
  font-weight: bold;
  white-space: nowrap;
}

input {
  width: 70px;
  text-align: right;
  padding: 7px;
}
.input {
  min-width: 70px;
  span {
    margin-right: 8px;
    text-align: right;
  }
  padding: 7px;
  cursor: pointer;
}

.clickable {
  cursor: pointer;
}
